import axios from "axios";
import { useEffect, useState } from "react"
import Loading from '../../assets/loading.gif';
import { MdVerified } from "react-icons/md";
import { axiosInstance, LODE_API } from "../../util/util";

export default function EventList() {

    const [events, setEvents] = useState(null);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        axios.get("https://lode.gg/api/landing/events")
            .then((res) => setEvents(res?.data ?? []))
            .catch(() => null);
    }, []);

    useEffect(() => {
        const controller = new AbortController();
        axiosInstance.get(LODE_API + "/landing/user", { signal: controller.signal })
            .then((res) => setUserData(res.data))
            .catch(() => null);
        return () => controller.abort();
    }, []);

    return <div className="flex flex-col items-center justify-center w-full my-16">
        {!events && <img className="mt-5" src={Loading} width={50} alt="Loading" />}
        {events && events.length === 0 && <div className="my-32 text-center items-center justify-center flex flex-col">
            <h1 className="text-6xl font-bold">No Active Events</h1>
            <p className="text-3xl">Looks like there aren't any active events. Check back later!</p>
        </div>}
        {events && events.length > 0 &&
            <div className="flex flex-col items-start justify-start w-[90%] md:w-[50%] xl:w-[65%]">
                {events.filter(e => e.featured).length > 0 && <>
                    <h1 className="text-4xl">Featured Events</h1>
                    <div className="grid-cols-1 xl:grid-cols-2 2xl:grid-cols-3 grid gap-5">
                        {events.filter(e => e.featured).map((event, index) => {
                            return <div key={index} className="bg-[#292929] rounded-xl mt-5">
                                <div className="relative">
                                    {/* Container for the split image */}
                                    <div className="relative">
                                        <img
                                            className="rounded-tl-xl rounded-tr-xl object-cover w-full h-48"
                                            src={event.thumbnail}
                                            alt={`${event.name} Thumbnail`}
                                        />
                                    </div>
                                </div>
                                {/* Content */}
                                <div className="px-4 py-3 mb-4 mt-3">
                                    <div className="flex flex-row items-end justify-start">
                                        <a href={`/event/${event.id}/`} className="text-white font-semibold text-2xl hover:underline transition-all">{event.name}</a>
                                        <span className="text-md ml-2 underline">by {event.author}</span>
                                        <MdVerified className="ml-2 text-blue-400" size={20} />
                                    </div>
                                    <p className="text-gray-300 text-sm mt-2">{event.description}</p>
                                    <div className="mt-5 space-x-3 flex flex-row items-center justify-start w-full">
                                        <a className="rounded-xl px-3 py-2 text-black transition-colors bg-green-400 w-full text-center hover:bg-green-500" href={`/event/${event.id}/`}>Apply for Event</a>
                                    </div>
                                </div>
                            </div>
                        })}
                    </div>
                </>}
                {events.filter(e => !e.featured).length > 0 && <>
                    <h1 className="text-4xl mt-3">Events</h1>
                    {events.filter(e => !e.featured).map((event, index) => {
                        return <div key={index} className="bg-[#292929] rounded-xl mt-5">
                            <div className="relative">
                                {/* Container for the split image */}
                                <div className="relative">
                                    <img
                                        className="rounded-tl-xl rounded-tr-xl object-cover w-full h-48"
                                        src={event.thumbnail}
                                        alt={`${event.name} Thumbnail`}
                                    />
                                </div>
                            </div>
                            {/* Content */}
                            <div className="px-4 py-3 mb-4 mt-3">
                                <div className="flex flex-row items-end justify-start">
                                    <a href={`/event/${event.id}/`} className="text-white font-semibold text-2xl hover:underline transition-all">{event.name}</a>
                                    <span className="text-md ml-2 underline">by {event.author}</span>
                                    <MdVerified className="ml-2 text-blue-400" size={20} />
                                </div>
                                <p className="text-gray-300 text-sm mt-2">{event.description}</p>
                                <div className="mt-5 space-x-3 flex flex-row items-center justify-start w-full">
                                    <a className="rounded-xl px-3 py-2 text-black transition-colors bg-green-400 w-full text-center hover:bg-green-500" href={`/event/${event.id}/`}>Apply for Event</a>
                                </div>
                            </div>
                        </div>
                    })}
                </>}
            </div>
        }
    </div>
}