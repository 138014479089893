import { useContext, useEffect, useState } from "react";
import { axiosInstance, extractYouTubeId, LODE_API } from "../../util/util";
import { PageContext } from "../../page-context";
import { useLocation, useParams } from "react-router-dom";
import Loading from '../../assets/loading.gif';
import { FaArrowLeft, FaArrowRight, FaCheck, FaHeart, FaHeartBroken, FaTrash } from "react-icons/fa";

export default function EventApplications() {

    const { screenSize } = useContext(PageContext);
    const { id } = useParams();

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [page, setPage] = useState(queryParams.get("page") ? parseInt(queryParams.get("page")) : 0);

    const updatePageParam = (newPage) => {
        queryParams.set("page", newPage); // Update without refresh
        setPage(newPage);
        window.history.replaceState(null, "", `?${queryParams.toString()}`);
    };

    const [userData, setUserData] = useState(null);
    const [eventData, setEventData] = useState(null);
    const [currentApplication, setCurrentApplication] = useState(null);

    useEffect(() => {
        axiosInstance.get(`https://lode.gg/api/events/${id}/applications`)
            .then((res) => setEventData(res?.data ?? []))
            .catch(() => null);
    }, []);

    useEffect(() => {
        setCurrentApplication(eventData?.applications?.filter(a => !a.status || a.status === "PENDING")?.[page]);
    }, [page]);

    useEffect(() => {
        setCurrentApplication(eventData?.applications?.filter(a => !a.status || a.status === "PENDING")?.[page]);
    }, [eventData]);

    useEffect(() => {
        if (!eventData || !userData) return;

        if (!eventData.access.includes(userData.uid))
            document.location.href = "/event/" + id;
    }, [eventData, userData]);

    return <div className="flex flex-col items-center justify-center my-3">
        <div className="text-center items-center justify-center flex flex-col rounded-xl w-[90%] lg:w-[65%] bg-[#121212] p-8">
            {/* <h1 className="text-3xl">Event Applications</h1> */}
            {!eventData && <img className="mt-5" src={Loading} width={50} alt="Loading" />}
            {eventData && eventData.applications.length === 0 && <div className="my-16 text-center items-center justify-center flex flex-col">
                <h1 className="text-6xl font-bold">No Applications</h1>
                <p className="text-3xl">Looks like there aren't any applications for this event. Check back later!</p></div>}
            {currentApplication && <div>
                <h1 className="text-3xl my-5">Application by {currentApplication.username}</h1>
                <iframe className="rounded-3xl" allowFullScreen={true} width="1100" height="619" src={`https://www.youtube.com/embed/${extractYouTubeId(currentApplication.youtube_link)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
                <div className="flex flex-row items-center justify-center mt-5 space-x-4">
                    <button onClick={() => {
                        updatePageParam(Math.max(0, page - 1));
                    }} className="rounded-full bg-[#212121] border border-[#464646] p-5">
                        <FaArrowLeft className="text-white transition-colors hover:text-red-500" size={30} />
                    </button>
                    <button className="rounded-full bg-[#212121] border border-[#464646] p-5" onClick={() => {
                        currentApplication.status = "ACCEPTED";
                        setCurrentApplication(eventData?.applications?.filter(a => !a.status || a.status === "PENDING")?.[0]);
                        axiosInstance.post(LODE_API + `/events/${id}/applications/${currentApplication.id}/`, { status: "BLACKLIST" })
                    }}>
                        <FaTrash className="text-white transition-colors hover:text-red-500" size={30} />
                    </button>
                    <button className="rounded-full font-bold bg-[#212121] border border-[#464646] p-5" onClick={() => {
                        currentApplication.status = "DENIED";
                        setCurrentApplication(eventData?.applications?.filter(a => !a.status || a.status === "PENDING")?.[0]);
                        axiosInstance.post(LODE_API + `/events/${id}/applications/${currentApplication.id}/`, { status: "DENY" })
                    }}>
                        <FaHeartBroken className="text-white transition-colors hover:text-red-500" size={30} />
                    </button>
                    <button className="rounded-full bg-[#212121] border border-[#464646] p-5" onClick={() => {
                        currentApplication.status = "DENIED";
                        setCurrentApplication(eventData?.applications?.filter(a => !a.status || a.status === "PENDING")?.[0]);
                        axiosInstance.post(LODE_API + `/events/${id}/applications/${currentApplication.id}/`, { status: "ACCEPT" })
                    }}>
                        <FaHeart className="text-white transition-colors hover:text-red-500" size={30} />
                    </button>
                    <button onClick={() => {
                        updatePageParam(Math.min(page + 1, eventData.applications.length));
                    }} className="rounded-full bg-[#212121] border border-[#464646] p-5">
                        <FaArrowRight className="text-white transition-colors hover:text-red-500" size={30} />
                    </button>
                </div>
            </div>}
        </div>
    </div>

}