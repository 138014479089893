import axios from "axios";
import { useEffect, useState } from "react";
import { BiSearch } from "react-icons/bi";
import { axiosInstance, MODRINTH_API, titleCase } from "../../util/util";
import PluginView from "../../components/PluginView";
import { PageContext } from "../../page-context";

export default function PluginDashboard() {

  const [plugins, setPlugins] = useState([]);
  const [pluginIps, setPluginIps] = useState({});
  const [pluginStatistics, setPluginStatistics] = useState([]);

  const { screenSize } = useState(PageContext);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/landing/plugins").then((res) => setPlugins(res?.data ?? [])).catch(() => null);
  }, []);

  useEffect(() => {
    axiosInstance.get("https://lode.gg/api/users/plugins").then((res) => {
        setPluginIps(res?.data ?? {});
    }).catch(() => null);
}, [pluginStatistics]);

  useEffect(() => {
    const fetchPluginData = async () => {
      const pluginDataPromises = plugins.map(async (plugin) => {
        try {
          if (plugin.paid) return plugin;

          const pluginRes = await axios.get(`${MODRINTH_API}/${plugin.id}`);
          const { description, downloads, icon_url, title, wiki_url, categories, loaders } =
            pluginRes.data;

          const versionRes = await axios.get(
            `${MODRINTH_API}/${plugin.id}/version`
          );
          const versions = versionRes.data[0]?.game_versions || [];
          const version = versionRes.data[0]?.version_number || "Unknown";

          return {
            categories,
            loaders,
            versions,
            description,
            downloads,
            icon_url,
            title,
            version,
            id: plugin.id,
            wiki_url,
          };
        } catch (error) {
          console.error(`Error fetching data for plugin ${plugin.id}:`, error);
          return null;
        }
      });

      const pluginData = await Promise.all(pluginDataPromises);
      setPluginStatistics((prev) => [...prev, ...pluginData.filter(Boolean)]);
    };

    if (plugins.length > 0) fetchPluginData();
  }, [plugins]);

  return (
    <div className="flex flex-row items-center justify-center">
      {/* Sidebar */}
      {/* <div className="bg-[#292929] rounded-xl px-5 py-5 w-[17rem]">
        <div className="text-gray-300 flex flex-row items-center justify-center w-max bg-[#3c3c3c]">
          <BiSearch size={25} />
          <input 
            className="bg-transparent focus:outline-none w-max"
            placeholder="Search"
          ></input>
        </div>
      </div> */}
      {/* Content */}
      <div className="ml-5 flex flex-col items-center justify-center mt-10">
        {/* <h1 className="inter-black text-4xl md:text-6xl text-center">
          Plugins
        </h1>
        <p className="text-md md:text-xl max-w-md md:max-w-3xl text-center text-gray-300 mb-3">
          View our catalogue of plugins that can help you out with your events or videos!
        </p> */}
        {/* Grid */}
        {/* Sidebar */}
        <div className="lg:items-start items-center justify-center flex-col lg:flex-row flex lg:mx-32 mb-12">
          <PluginView
            categoryFilter={[]}
            plugins={plugins}
            pluginIps={pluginIps}
            loaderFilter={[]}
            pluginStatistics={pluginStatistics}
            screenSize={screenSize}
            search={""}
          />
        </div>
      </div>
    </div>
  );
}
