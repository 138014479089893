import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { MdVerified } from "react-icons/md";
import { useParams } from "react-router-dom";
import { PageContext } from "../../page-context";
import { axiosInstance, extractYouTubeId, LODE_API } from "../../util/util";
import DropdownBulletPoint from "../../components/DropdownBulletPoint";

export default function EventPage() {

    const { screenSize } = useContext(PageContext);
    const { id } = useParams();

    const [eventData, setEventData] = useState(null);

    const [verifiedUsername, setVerifiedUsername] = useState(null);
    const [username, setUsername] = useState("");
    const [errorMessage, setErrorMessage] = useState(null);
    const [applicationErrorMessage, setApplicationErrorMessage] = useState(null);
    const [profilePicture, setProfilePicture] = useState(null);

    const [age, setAge] = useState(null);
    const [youtubeLink, setYoutubeLink] = useState(null);

    const [userData, setUserData] = useState(null);

    const [status, setStatus] = useState(null);

    useEffect(() => {
        axiosInstance.get(LODE_API + "/landing/user")
            .then((res) => setUserData(res.data))
            .catch(() => null);

        axiosInstance.get(LODE_API + `/events/${id}/status`)
            .then((res) => {
                if (!res.data.error) 
                    setStatus(res.data);
            })
            .catch(() => null);
    }, []);

    useEffect(() => {
        console.log(status);
    }, [status]);

    useEffect(() => {
        axios.get(`https://lode.gg/api/events/${id}`)
            .then((res) => setEventData(res?.data ?? null))
            .catch(() => document.location.href = "/events");
    }, []);

    return <>
        <div className="flex flex-col items-center justify-center my-16">
            {screenSize < 1000 && <div className="text-center max-w-lg">
                {eventData && <h1 className="text-6xl mb-6">{eventData.name}</h1>}
                <span className="text-2xl">Unfortunately, you are unable to apply for events on mobile. Please switch to a desktop or laptop to apply for events.</span>
            </div>}
            {screenSize >= 1000 && eventData && <div className="text-center items-center justify-center flex flex-col rounded-xl w-[90%] lg:w-[65%] bg-[#121212]">
                <img className="rounded-tl-xl rounded-tr-xl w-full h-64 object-cover" src={eventData.thumbnail} alt={`${eventData.name} Thumbnail`} />
                <div className="p-12 flex flex-col items-center justify-center">
                    <h1 className="text-6xl font-bold">{eventData.name}</h1>
                    <div className="flex mt-3 flex-row items-center justify-center">
                        <h1 className="text-2xl text-gray-400 underline">by {eventData.author}</h1>
                        <MdVerified className="ml-2 text-blue-400" size={20} />
                    </div>
                    <p className="text-xl text-gray-400 max-w-5xl mt-3 mb-8">{eventData.description}</p>
                    {eventData.showcase_url && <iframe className="mb-8" width={screenSize > 1000 ? "720" : "480"} height={screenSize > 1000 ? "480" : "240"} src={eventData.showcase_url} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>}
                    {userData ? status ? <div className="flex flex-col items-center justify-center">
                        <h1 className="text-5xl max-w-4xl font-bold">Application</h1>
                        <p className="text-xl mt-2 text-gray-400 max-w-3xl">You've already applied for {eventData.name}. Please make sure your DMs are open for a response on your application! You will be DMed by the Lodestone bot!</p>
                        {status.youtube_link && <iframe className="my-8" width={screenSize > 1000 ? "720" : "480"} height={screenSize > 1000 ? "480" : "240"} src={`https://www.youtube.com/embed/${extractYouTubeId(status.youtube_link)}`} title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>}
                    </div> : <div className="text-center items-center justify-center flex flex-col mt-10 mb-14">
                        <h1 className="text-5xl max-w-4xl font-bold">Application</h1>
                        <p className="text-xl mt-2 text-gray-400 max-w-3xl">You are applying for {eventData.name} by {eventData.author}. Please be respectful and honest in your application and stay true to your character.</p>
                        <div className="flex flex-row items-center justify-center mt-6">
                            You are logged in as
                            <img src={userData.icon_url} className="ml-2 w-8 rounded-full" alt="discord profile" />
                            <span className="text-gray-300 ml-2">{userData.id}</span>
                        </div>

                        {/* Take Minecraft Username */}
                        <div className="flex flex-col items-start justify-start mt-6">
                            <div className="flex flex-col items-start justify-start mt-2">
                                <DropdownBulletPoint title="Requirements" options={[
                                    "You must be above the age of 13+.",
                                    "You must own a legitimate version of a Java Minecraft account.",
                                    {
                                        title: "Your Microphone",
                                        subOptions: ["must be clear and concise.", "must prevent background noises such as an AC or PC Fan.", "must not have other people speaking in the background."],
                                    },
                                    {
                                        title: "Your Minecraft Gameplay",
                                        subOptions: ["must not overpower your voice.", "must have your footage to have at least 60 fps."],
                                    },
                                    {
                                        title: "Your Video",
                                        subOptions: ["must be an UNLISTED video on YouTube.", "must be around 1-2 minutes long.", "must not a watermark like \"bandicam\" or \"wondershare filmora\".", "must not be overly edited. A couple cuts here and there is fine."]
                                    },
                                ]} />
                                <DropdownBulletPoint title="Application Questions" options={[
                                    "What is your Minecraft IGN?",
                                    {
                                        title: "Why do you want to join our event?",
                                        subOptions: ["Saying \"because I want to be in [YOUTUBER]'s video\" won't get you accepted."],
                                    },
                                    "Why should you be picked over others?",
                                    "What can you contribute to our event?"
                                ]} />
                            </div>

                            <h2 className="text-3xl mb-2">Username</h2>
                            <span className="text-gray-400 max-w-2xl text-left mb-2">Remember that impersonating a player or using alt accounts to apply will result in a blacklist in Lodestone and possibly other event servers.</span>
                            <div className="flex flex-row items-center justify-start w-[80vh]">
                                {profilePicture && (<img src={profilePicture} alt="profile" className="rounded-xl w-10 h-10 mr-3" />)}
                                <input
                                    type="text"
                                    placeholder="Minecraft Username"
                                    className="rounded-md bg-[#232323] px-5 py-2 text-gray w-full"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                                {/* Verify username */}
                                <button
                                    className={`rounded-md bg-[#4AEDD9] px-5 py-2 text-center text-gray font-extrabold text-black ml-3`}
                                    onClick={() => {
                                        setErrorMessage(null);
                                        // Verify username
                                        axios.get(`https://playerdb.co/api/player/minecraft/${username}`).then((res) => {
                                            if (res.data.code === "minecraft.invalid_username") {
                                                setErrorMessage("Invalid username");
                                            } else if (res.data.code === "player.found") {
                                                setProfilePicture(res.data.data.player.avatar);
                                                setVerifiedUsername(username);
                                            } else {
                                                setErrorMessage(`An unexpected error has occured! | ${res.data.code}`);
                                            }
                                        }).catch(err => {
                                            setErrorMessage("Invalid Username");
                                        });
                                    }}>Verify</button>
                            </div>
                            {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
                        </div>
                        <div className="flex flex-col items-start justify-start mt-6">
                            <h2 className="text-3xl mb-2">Age</h2>
                            <input
                                type="number"
                                placeholder="Age"
                                className="rounded-md bg-[#232323] px-5 py-2 text-gray w-[80vh]"
                                min={7}
                                max={100}
                                value={age}
                                onChange={(e) => setAge(e.target.value)}
                            />
                        </div>
                        <div className="flex flex-col items-start justify-start my-6">
                            <h2 className="text-3xl mb-2">YouTube Link</h2>
                            <span className="text-gray-400 max-w-2xl text-left mb-2">Remember to make sure that your video isn't a YouTube short. Please follow our requirements before submitting your video and that you review it before submitting!</span>
                            <input
                                type="text"
                                placeholder="YouTube Link"
                                className="rounded-md bg-[#232323] px-5 py-2 text-gray w-[80vh]"
                                value={youtubeLink}
                                onChange={(e) => setYoutubeLink(e.target.value)}
                            />
                        </div>

                        {applicationErrorMessage && <p className="text-red-500 mt-2">{applicationErrorMessage}</p>}
                        <button className="px-8 py-4 bg-green-400 w-[75%] text-black font-bold rounded-xl mt-2" onClick={() => {
                            if (verifiedUsername === null) {
                                setErrorMessage("Please verify your username before applying.");
                                return;
                            }

                            setApplicationErrorMessage(null);
                            axiosInstance.post(LODE_API + `/events/${id}/apply?username=${verifiedUsername}&age=${age}&youtube_link=${encodeURI(youtubeLink)}`)
                                .then(res => {
                                    if (res.data.error) {
                                        setApplicationErrorMessage(res.data.error);
                                    } else {
                                        window.location.reload();
                                    }
                                });
                        }}>Click to Apply</button>
                    </div> : <></>}
                </div>
            </div>}
        </div >
    </>;
}