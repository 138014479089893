import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { PageContext } from "../../page-context";
export default function Memberships() {

  const { screenSize } = useContext(PageContext);

  const [diamond, setDiamond] = useState(null);
  const [host, setHost] = useState(null);
  const [isMonthly, setMonthly] = useState(true);
  const [verifiedUsername, setVerifiedUsername] = useState(null);
  const [username, setUsername] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    axios
      .get("https://lode.gg/api/landing/memberships")
      .then((res) => {
        console.log(res.data);
        setDiamond(res?.data[0] ?? []);
        setHost(res?.data[1] ?? []);
      })
      .catch(() => null);
  }, []);

  const togglePlan = () => {
    setMonthly(!isMonthly);
  };

  return (
    <div>
      {screenSize <= 1200 && <div className="mx-20 items-center justify-center flex flex-col my-32">
        <h1 className="text-4xl font-bold">Hello Traveller!</h1>
        <h1 className="text-center max-w-lg text-xl text-gray-300">Unfortunately, to prevent users who play Minecraft Bedrock from purchasing a rank, we encourage that you purchase a rank via desktop since the majority plays in Minecraft Java! We're sorry for the inconvenience!</h1>
      </div>}
      {screenSize > 1200 && diamond && (
        <div className="flex items-center justify-center flex-col my-10">
          <div className="flex flex-col items-start justify-start bg-[#121212] p-16 w-[125vh]">
            <div className="flex flex-row items-center justify-center">
              <h1 className="font-extrabold mb-3 text-4xl md:text-6xl text-center">
                Upgrade to <span className="text-[#21C6B6]">Diamond</span>
              </h1>
              <img
                src={diamond.icon_url}
                width={80}
                className="ml-3 mb-2 animate-bounce"
                alt="tier"
              />
            </div>
            <p className="text-md md:text-xl max-w-md md:max-w-3xl text-gray-300">
              Subscribe to Diamond to unlock exclusive perks in Beacon, support
              Lodestone's development, and earn a unique profile badge! Cancel
              anytime.
            </p>
            <div className="flex items-center justify-center mt-4">
              <div className="relative w-40 h-12">
                <div
                  className={`absolute top-0 left-0 w-20 h-full bg-[#4AEDD9] rounded-2xl transition-all duration-300 transform ${!isMonthly ? "translate-x-full bg-red-500" : ""
                    }`}
                />
                <button
                  onClick={togglePlan}
                  className={`absolute transition-colors left-0 w-1/2 h-full text-center font-semibold z-5 ${!isMonthly ? "text-gray-400" : "text-black"
                    }`}
                >
                  Monthly
                </button>
                <button
                  onClick={togglePlan}
                  className={`absolute transition-colors right-0 w-1/2 h-full text-center font-semibold z-5 ${!isMonthly ? "text-black" : "text-gray-400"
                    }`}
                >
                  Annual
                </button>
              </div>
            </div>
            <p className="text-gray-300 mt-3">Save 16% with annual billing!</p>
            <div className="my-6 flex flex-col">
              <h2 className="text-3xl mb-5">
                $
                {isMonthly
                  ? diamond.prices.monthly
                  : diamond.prices.annual}
                <span className="text-sm">
                  /{isMonthly ? "month" : "year"}
                </span>
              </h2>
              <ul>
                {diamond.perks.map((perk) => {
                  return <li>- {perk}</li>;
                })}
              </ul>
              {/* Take Minecraft Username */}
              <div className="flex flex-row items-center justify-start mt-6">
                {profilePicture && (<img src={profilePicture} alt="profile" className="rounded-full w-10 h-10 mr-3" />)}
                <input
                  type="text"
                  placeholder="Minecraft Username"
                  className="rounded-md bg-[#232323] px-5 py-2 text-gray"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {/* Verify username */}
                <button
                  className={`rounded-md bg-[#4AEDD9] px-5 py-2 text-center text-gray font-extrabold text-black ml-3`}
                  onClick={() => {
                    setErrorMessage(null);
                    // Verify username
                    axios.get(`https://playerdb.co/api/player/minecraft/${username}`).then((res) => {
                      if (res.data.code === "minecraft.invalid_username") {
                        setErrorMessage("Invalid username");
                      } else if (res.data.code === "player.found") {
                        setProfilePicture(res.data.data.player.avatar);
                        setVerifiedUsername(username);
                      } else {
                        setErrorMessage(`An unexpected error has occured! | ${res.data.code}`);
                      }
                    }).catch(err => {
                      setErrorMessage("Invalid Username");
                    });
                  }}>Verify</button>
              </div>
              {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
              <a
                href={verifiedUsername === null ? null : `https://lode.gg/shop/packages/${diamond.id}/checkout?type=${isMonthly ? "monthly" : "annual"}&username=${verifiedUsername}`}
                className={`${verifiedUsername ? "bg-[#4AEDD9]" : "cursor-not-allowed bg-gray-500"} rounded-md px-5 py-2 text-center text-black mt-6 font-extrabold w-[30vh]`}
                aria-disabled={verifiedUsername === null}
                target="_blank"
                rel="noreferrer"
              >
                Subscribe<br /> $
                {isMonthly
                  ? diamond.prices.monthly
                  : diamond.prices.annual}
                /{isMonthly ? "month (auto-renews)" : "year (single purchase)"}
              </a>
            </div>
          </div>
        </div>
      )}
      {screenSize > 1200 && host && (
        <div className="flex items-center justify-center flex-col my-10">
          <div className="flex flex-col items-start justify-start bg-[#121212] p-16 w-[125vh]">
            <div className="flex flex-row items-center justify-center">
              <h1 className="font-extrabold mb-3 text-4xl md:text-6xl text-center">
                Upgrade to <span className="text-[#f959ff] mr-4">Host</span>
              </h1>
              <img
                src={host.icon_url}
                width={80}
                className="ml-3 mb-2 animate-bounce"
                alt="tier"
              />
            </div>
            <p className="text-md md:text-xl max-w-md md:max-w-3xl text-gray-300">
              Subscribe to Host to host private or public events, with custom scenarios in Beacon. Cancel anytime.
            </p>
            <div className="my-6 flex flex-col">
              <h2 className="text-3xl mb-5">
                ${host.prices.biweekly}
                <span className="text-sm">/bi-weekly (2 weeks)</span>
              </h2>
              <ul>
                {host.perks.map((perk) => {
                  return <li>- {perk}</li>;
                })}
              </ul>
              {/* Take Minecraft Username */}
              <div className="flex flex-row items-center justify-start mt-6">
                {profilePicture && (<img src={profilePicture} alt="profile" className="rounded-full w-10 h-10 mr-3" />)}
                <input
                  type="text"
                  placeholder="Minecraft Username"
                  className="rounded-md bg-[#232323] px-5 py-2 text-gray"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {/* Verify username */}
                <button
                  className={`rounded-md bg-[#f959ff] px-5 py-2 text-center text-gray font-extrabold text-black ml-3`}
                  onClick={() => {
                    setErrorMessage(null);
                    // Verify username
                    axios.get(`https://playerdb.co/api/player/minecraft/${username}`).then((res) => {
                      if (res.data.code === "minecraft.invalid_username") {
                        setErrorMessage("Invalid username");
                      } else if (res.data.code === "player.found") {
                        setProfilePicture(res.data.data.player.avatar);
                        setVerifiedUsername(username);
                      } else {
                        setErrorMessage(`An unexpected error has occured! | ${res.data.code}`);
                      }
                    }).catch(err => {
                      setErrorMessage("Invalid Username");
                    });
                  }}>Verify</button>
              </div>
              {errorMessage && <p className="text-red-500 mt-2">{errorMessage}</p>}
              <a
                href={verifiedUsername === null ? null : `https://lode.gg/shop/packages/${host.id}/checkout?type=biweekly&username=${verifiedUsername}`}
                className={`${verifiedUsername ? "bg-[#f959ff]" : "cursor-not-allowed bg-gray-500"} rounded-md px-5 py-2 text-center text-black mt-6 font-extrabold w-[30vh]`}
                aria-disabled={verifiedUsername === null}
                target="_blank"
                rel="noreferrer"
              >
                Subscribe<br /> ${host.prices.biweekly}/bi-weekly (auto-renews)
              </a>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}