import React, { useState } from "react";

const DropdownBulletPoint = ({ title, options }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => setIsOpen(!isOpen);

    return (
        <div className="text-left mb-6">
            <div className="flex flex-row items-center justify-start cursor-pointer" onClick={toggleDropdown}>
                <h2 className="text-3xl">{title}</h2>
                <svg
                    className={`w-5 h-5 ml-2 transition-transform ${isOpen ? "rotate-0" : "-rotate-90"
                        }`}
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                    />
                </svg>
            </div>

            {isOpen && (
                <div className="z-10 w-[80vh] text-white mt-2 origin-top-right rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                    <ul className="py-2 text-md">
                        {options.map((option, index) =>
                            typeof option === "string" ? (
                                // Render a single-level option
                                <li
                                    key={index}
                                    className="px-4 py-2 flex items-center"
                                >
                                    <span className="mr-2">•</span> {option}
                                </li>
                            ) : (
                                // Render an option with sub-bullet points
                                <li key={index} className="px-4 py-2">
                                    <div className="flex items-center">
                                        <span className="mr-2">•</span> {option.title}
                                    </div>
                                    <ul className="ml-6 mt-1">
                                        {option.subOptions.map((subOption, subIndex) => (
                                            <li
                                                key={subIndex}
                                                className="flex items-center"
                                            >
                                                <span className="mr-2">•</span> {subOption}
                                            </li>
                                        ))}
                                    </ul>
                                </li>
                            )
                        )}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default DropdownBulletPoint;